<script>
import { ElMessage } from 'element-plus'
import { couponTrack, getAllCoupons, request } from './helper'

export default {
  name: 'CouponsDialog',
  props: {
    pageName: {
      type: String,
      default: '',
      required: true,
    },
    /** 优惠券的渠道，每个渠道不一样 */
    channel: {
      type: String,
      default: '',
    },
  },
  emits: ['getCallback'],
  data() {
    return {
      showDialog: false,
      couponInfo: {},
    }
  },
  computed: {
    promocodes() {
      return (this.couponInfo.itemList || []).map(item => item.batchNo) || []
    },
  },
  mounted() {
    this.fetchCoupons()
  },
  methods: {
    fetchCoupons() {
      if (!this.pageName) {
        return
      }

      request({
        url: '/sea_octopus_api/popup/getCouponPopup',
        baseURL: '/sea_shell',
        method: 'POST',
        body: {
          pageName: this.pageName,
          channel: this.channel,
        },
      }).then((res) => {
        if (res.status === 200) {
          this.couponInfo = res.body
          // this.couponInfo.itemList = [...this.couponInfo.itemList, ...this.couponInfo.itemList, ...this.couponInfo.itemList, ...this.couponInfo.itemList]
          this.showDialog = true
          couponTrack({
            eventid: 12047,
            action: 'show',
            label: 'PopUp',
            value: { PopUpType: this.couponInfo.receiveType, PromoCode: this.promocodes },
          })
        }
        console.log(res, '优惠券数据')
      }).catch((err) => {
        console.log('获取优惠券信息失败', err)
      })
    },
    closeTicket() {
      this.showDialog = false
    },
    /**
     * 领取所有优惠券
     */
    getAllCouponsHandler() {
      // 直接发放的不请求接口
      if (+this.couponInfo?.receiveType === 1) {
        this.$emit('getCallback', { status: 1 })
        this.closeTicket()
        return
      }
      const trackEvent = (isOk) => {
        couponTrack({
          eventid: 12046,
          action: 'click',
          label: 'PopUp',
          value: { PopUpType: this.couponInfo.receiveType, PromoCode: this.promocodes, PromoCodeState: isOk },
        })
      }
      getAllCoupons({
        configId: this.couponInfo.configId,
        channel: this.channel,
        pageName: this.pageName,
      })
        .then((result) => {
          if (result.status === 200) {
            this.$emit('getCallback', { status: 1 })
            ElMessage.success(result.message)
            trackEvent('1')
            this.closeTicket()
          }
          else {
            throw result
          }
        }).catch((err) => {
          console.error('领取失败', err)
          trackEvent('0')
          this.$emit('getCallback', { status: 0 })
          ElMessage.error(err?.message || 'failed')
        })
    },
  },
}
</script>

<template>
  <div v-if="showDialog" class="box">
    <div class="layer" @touchmove.prevent />
    <div
      class="coupons" :style="{
        background: `url(${couponInfo.bgImg}) no-repeat top center / contain, linear-gradient(180deg, #00C868 15.69%, #96E0B8 99.98%)`,
      }"
    >
      <div class="title">
        {{ couponInfo.title }}
      </div>
      <ul class="max-h-[264px] overflow-scroll ticket-list">
        <li v-for="item in couponInfo.itemList" :key="item.batchNo" class="item flex ">
          <div class="item-l mx-[32px]">
            <img class="icon" :src="item.iconPopUrl">
          </div>
          <div class="item-r">
            <p class="tit bold line-clamp-2">
              {{ item.title }}
            </p>
            <p class="f12">
              {{ item.promoTitle }}
            </p>
          </div>
        </li>
      </ul>
      <div class="dilog-bottom">
        <div class="btn font-bold cursor-pointer" @click="getAllCouponsHandler">
          {{ couponInfo.buttonText }}
        </div>
      </div>
      <img src="../../assets/img/coupons/close.png" alt="" class="close cursor-pointer " @click="closeTicket">
    </div>
  </div>
</template>

<style lang="scss" scoped>
@tailwind base;
@tailwind components;
@tailwind utilities;

.bold {
  font-weight: bold;
}

.flex {
  display: flex;
  align-items: center;
}

.f12 {
  font-size: 12px;
}

.box {
  // display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.layer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 100;
}

.coupons {
  // position: fixed;
  // top: 50%;
  // left: 50%;
  // right: 40px;
  // transform: translate(-50%, -50%);
  min-height: 100px;
  width: 390px;
  z-index: 101;
  border-radius: 16px;
  background-position: top right;
  background-repeat: no-repeat;

  .title {
    font-size: 22px;
    color: #faff00;
    // height: 60px;
    line-height: 1.4;
    margin: 16px 0;
    text-align: center;
    font-weight: bold;
  }

  ul {
    padding: 0 16px 16px;
  }

  .item {
    border-image: url('./ticket-popup.png') 6*3 8*3 6*3 102*3 fill;
    border-image-width: 6px 8px 6px 102px;

    border-radius: 8px;

    width: 358px;
    // height: 80px;
    padding: 16px 0;
    flex-shrink: 0;

    &:last-child {
      margin-bottom: 0;
    }

    &-l {
      display: flex;
      justify-content: center;
      align-items: center;

      .icon {
        width: 32px;
        height: 32px;
        background: no-repeat center center / 100% 100%;
      }

    }

    &-r {
      flex: 1;
      padding: 0 32px;

      .tit {
        font-size: 20px;
      }
    }

    &+.item {
      margin-top: 8px;
    }
  }
}

.dilog-bottom {
  border-radius: 0 0 16px 16px;
  background-color: #fff;
  padding: 16px;
  box-shadow: 0 -2px 6px 0 #0000000d;

  .btn {
    height: 56px;
    line-height: 56px;
    border-radius: 8px;
    width: 100%;
    color: #fff;
    background-color: #00C868;
    font-size: 18px;
    text-align: center;
  }
}

.close {
  position: absolute;
  bottom: -84px;
  height: 28px;
  width: 28px;
  left: 50%;
  margin-left: -14px;
}

.ticket-list {
  overscroll-behavior: none;

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}
</style>
