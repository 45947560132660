<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { useCommonConfig } from '~/store/common'
import GuideDownload from '@/components/index/guideDownload.vue'
import { http } from '@/utils/http'
import { impactClickEvent } from '@/utils/impactClick'
import CouponList from '@/components/coupons/couponsList.vue'
import CouponDialog from '@/components/coupons/couponsDialog.vue'
import linkEntry from '@/components/index/linkEntry.vue'
import { trackEvent } from '~/utils/track'

definePageMeta({
  layout: 'home',
})

enum TabName {
  Flight,
  Hotel,
  Train,
}
enum TabNameStr {
  '机票',
  '酒店',
  '火车票',
}

enum TabNameStrType {
  'air',
  'hotel',
  'train',
}

const commonConfig: any = useCommonConfig()
const router = useRouter()
const route = useRoute()
// const topEntry = [
// {
//     id: 1,
//     name: 'Flight',
//     url: ' https://www.hopegoo.com/zh-hk/main-flights',

//   }, {
//     id: 2,
//     name: 'Hotel',
//     url: ' https://www.hopegoo.com/zh-hk/main-hotels',
//   }, {
//     id: 3,
//     name: 'Train',
//     url: ' https://www.hopegoo.com/zh-hk/main-trains',
//   }
// ]
const data = reactive({
  // tabIndex {number} 0-机票 1-酒店 2-火车票
  // route.query.tab {string} 0-机票 1-酒店
  tabIndex: getUrlTab(route),
  showCityRecommend: true,
  banners: [],
  // topEntry: topEntry,
})


const { locale, mergeLocaleMessage, getLocaleCookie, setLocaleCookie } = useI18n()
const switchLocalePath = useSwitchLocalePath()
const { t } = useLang()
const locale1 = ref(locale.value);
// 展示目的地模块
const showIndexDestinationModule = computed(() => {
  const needHideLangs = commonConfig?.config?.destination?.needHideLangs || []
  return data.tabIndex === TabName.Flight && !needHideLangs.includes(locale.value)
})

// const banners = computed(() => {
//   const tabName = TabName[data.tabIndex].toLocaleLowerCase()
//   return commonConfig.businessBanners[tabName] || []
// })

useHead({
  title: t('home.b_tdk_seo_title'),
  meta: [
    { name: 'keywords', content: t('home.b_tdk_seo_keywords') },
    { name: 'description', content: t('home.b_tdk_seo_description') },
    { name: 'author', content: t('common.b_author') },
    { name: 'copyright', content: t('common.b_company') },
  ],
  bodyAttrs: {},
  script: [
    {
      src: '/intlPC/js/jquery-3.3.1.min.js',
      type: 'text/javascript',
    },
    {
      src: '/intlPC/js/calendar.js?v=202308023',
      type: 'text/javascript',
      body: true,
    },
    {
      children: 'window.pageview = \'/首页\'',
      type: 'text/javascript',
    },
  ],
  link: [
    {
      rel: 'stylesheet',
      href: '/intlPC/css/calendar.css?v=202308023',
      body: true,
    },
    {
      rel: 'canonical',
      href: `https://www.hopegoo.com/${locale.value}/`,
      body: true,
    }, {
      rel: 'alternate',
      href: `https://www.hopegoo.com/m/${locale.value}/`,
      media: 'only screen and (max-width: 640px)',
      body: true,
    },
  ],
})

// 日语下访问酒店和火车票时需要跳到兜底的英文
function checkLang(tabIndex: number) {
  const showConfirm = locale.value === 'ja-jp' && [TabName.Hotel, TabName.Train].includes(tabIndex)

  if (showConfirm) {
    ElMessageBox.alert(t('home.b_hotel_confirm_msg'), {
      confirmButtonText: t('home.b_hotel_confirm_btn_ok'),
    }).then(() => {
      const { href } = router.resolve({
        path: '/en-us',
        query: { ...route.query, tab: TabName[tabIndex] },
      })
      window.location.href = href
    }).catch(() => {
      const { href } = router.resolve({
        path: route.path,
        query: { ...route.query, tab: TabName[0] },
      })
      window.location.href = href
    })
  }
}

function hotelShowCheck() {
  const clientInfo = {
    deviceId: useComCookie('H5CookieId').value,
    lang: useComCookie('locale').value,
    country: '',
    currency: useComCookie('currency').value,
  }
  http({
    url: '/sea_octopus_api/hotel/hotelShowCheck',
    baseURL: '/sea_shell',
    method: 'post',
    body: {
      channelId: 1,
      clientInfo: JSON.stringify(clientInfo),
      pageType: 'homePage',
      projectType: 'hotel',
    },
  }).then((res) => {
    data.showCityRecommend = res.body
  })
}

function topTab(tab: string) {
  // const tabIndex = TabName[tab]
  // setTab(tabIndex)
}

function setTab(index: number) {
  if (index === data.tabIndex) { return }
  const prevTab = TabNameStr[data.tabIndex]
  const tab = TabNameStr[index]
  const tabType = TabNameStrType[index]
  $utils.sendEvent('', '', 'tab切换', `^切换前:${prevTab}^切换后:${tab}`) // checkLang 会有弹框暂停了执行

  checkLang(index)
  getBannerList(tabType)

  router.replace({
    path: route.path,
    query: { ...route.query, tab: TabName[index] },
  })
}

function getUrlTab(route: any) {
  const urlTabName: keyof typeof TabName = route.query.tab as any
  const tabIndexList = [0, 1, 2] // 0-机票 1-酒店 2-火车票

  if (!urlTabName) {
    return TabName.Flight
  }

  // 处理数字索引，兼容已经被使用的场景
  if (tabIndexList.includes(Number(urlTabName))) {
    return Number(urlTabName)
  }

  // 处理字符串索引 Train、Flight、Hotel
  const [firstLetter, ...rest] = urlTabName.replace(/\s/ig, '')
  const firstLetterUpper = firstLetter.toUpperCase()
  const TabNameStr = `${firstLetterUpper}${rest.join('').toLowerCase()}` as keyof typeof TabName
  if (tabIndexList.includes(TabName[TabNameStr])) {
    return TabName[TabNameStr]
  }

  return TabName.Flight
}

async function getBannerList(tabType: string) {
  const isLogin = useComCookie('sectoken').value ? 1 : 0
  const clientInfo = {
    deviceId: useComCookie('H5CookieId').value,
    lang: useComCookie('locale').value,
    country: '',
    currency: useComCookie('currency').value,
  }
  const dataList = await request({
    url: '/sea_shell/sea_turtle_api/operationPositionConfig/all',
    method: 'POST',
    data: {
      loginMark: isLogin,
      pageType: 'homePage',
      projectType: tabType,
      clientInfo: JSON.stringify(clientInfo),
    },
    includeClientInfo: false,
  })
  const banner_List = [] as any
  if (dataList && dataList.length > 0) {
    dataList.forEach((i: any) => {
      if (i.operationType === 1) {
        // banner
        i.publishConfigs.forEach((j: any) => {
          banner_List.push({
            imgUrl: j.imgUrl,
            linkUrl: j.jumpUrl,
            title: j.activityTitle,
            adv: j.advertisementMark,
            id: j.id,
            priority: j.priority,
            configId: j.configId
          })
        })
        data.banners = banner_List
        // Homepage-Operating首页运营位-banner曝光
        if(banner_List.length > 0) {
          const index = 0;
          trackEvent({
            eventid: 12200,
            label: 'banner',
            category: 'PC-homepage',
            action: 'show',
            value: {
              "operatingId": banner_List[index].configId,
              "operatingurl": banner_List[index].linkUrl,
              "operatingSort": banner_List[index].priority
            },
          })
        }
      }
    })
  }
  else {
    data.banners = []
  }
}

watch(route, (newValue) => {
  data.tabIndex = getUrlTab(newValue)
  if (data.tabIndex === 1) {
    hotelShowCheck()
  }
})

const refId = computed(() => {
  const refIdValue = route.query.refId || route.query.refid || ''

  if (typeof refIdValue === 'string') {
    return refIdValue // 如果是 string，直接返回
  }
  else if (Array.isArray(refIdValue) && refIdValue.length > 0) {
    return refIdValue[0] as string // 如果是数组，返回第一个元素
  }
  else {
    return '' // 在其他情况下，返回空字符串
  }
})

const irclickid = computed(() => {
  const irclickidValue = route.query.irclickid

  if (typeof irclickidValue === 'string') {
    return irclickidValue // 如果是 string，直接返回
  }
  else if (Array.isArray(irclickidValue) && irclickidValue.length > 0) {
    return irclickidValue[0] as string // 如果是数组，返回第一个元素
  }
  else {
    return '' // 在其他情况下，返回空字符串
  }
})

watch(irclickid, (newValue) => {
  if (process.client) {
    if (newValue) {
      sessionStorage.setItem('irclickid', newValue)
    }
    else {
      sessionStorage.removeItem('irclickid')
    }
  }
}, { immediate: true })

watch(refId, (newValue) => {
  if (process.client) {
    if (newValue) {
      sessionStorage.setItem('refId', newValue)
    }
    else {
      sessionStorage.removeItem('refId')
    }
  }
}, { immediate: true })

onMounted(() => {
  const tabName = TabNameStr[data.tabIndex]
  const tabType = TabNameStrType[data.tabIndex]
  const tid = setTimeout(() => {
    clearTimeout(tid)
    if (process.client) {
      getBannerList(tabType)
      if (data.tabIndex === 1) {
        hotelShowCheck()
      }
    }
  })

  const currency = useComCookie('currency').value || ''
  const isLogin = useComCookie('sectoken').value ? '已登录' : '未登录'

  $utils.sendEvent('', '曝光', '首页', `^${tabName}^${locale.value}^${currency}^登录状态:${isLogin}`)
  try {
    useComCookie('sectoken').value && impactClickEvent(refId.value, 'order', irclickid.value)
  }
  catch (error) {

  }
  checkLang(data.tabIndex)
})
</script>

<template>

  <div class="top_search">
    <div class="top-line"></div>
    <linkEntry ></linkEntry>
    <div class="top-content">
        <p class="top-title">{{ t('home.b_home_default_title') }}</p>
        <h1 :class="['top-desc',locale == 'ja-jp'?'jp':'']">{{ t('home.b_home_default_desc') }}</h1>
        <div 
        v-if="locale !== 'ja-jp'"
        :class="['download-app', locale === 'ja-jp' ? 'jp' : (locale === 'zh-hk' || locale == 'zh-tw' ? 'cn' : 'en')]" >
            <a target="_blank" href="https://apps.apple.com/hk/app/hopegoo-同程旅行旗下全新線上旅遊預訂平台/id6479733264">
              <div class="download-area apple-area"></div>
            </a>
            <a target="_blank" href="https://play.google.com/store/apps/details?id=com.beyondtrip.hopegoo">
              <div class="download-area google-area"></div>
            </a>
        </div>
    </div>
    <div class="top_box">
      
      <div class="tab_ul">
        <div class="tab_li" :class="{ active: data.tabIndex === TabName.Flight }" @click="setTab(TabName.Flight)">
          {{ t('home.b_iflight_flights') }}
        </div>
        <div class="cut_line" />
        <div class="tab_li" :class="{ active: data.tabIndex === TabName.Hotel }" @click="setTab(TabName.Hotel)">
          {{ t('home.s_hotel_tab') }}
        </div>
        <div class="cut_line" />
        <div class="tab_li" :class="{ active: data.tabIndex === TabName.Train }" @click="setTab(TabName.Train)">
          {{ t('home.s_train_tab') }}
        </div>
      </div>
      

      <div class="tab_content" :class="{ flight_content: data.tabIndex === TabName.Flight }">
        <IndexFlight v-show="data.tabIndex === TabName.Flight" />
        <HotelSearch v-show="data.tabIndex === TabName.Hotel" :is-visible="data.tabIndex === TabName.Hotel" />
        <TrainSearch v-show="data.tabIndex === TabName.Train" />
      </div>
    </div>
  </div>

  <div class="pb-[43px]">
    <CouponList page-name="homePage" channel="20002" />
  </div>

  <IndexBusinessBanner v-show=" data.banners && data.banners.length" :list="data.banners" :tab-name="TabNameStr[data.tabIndex]" />
  <!-- 酒店专属 -->
  <HotelRecommend v-if="(data.tabIndex === TabName.Hotel) && data.showCityRecommend" />
  <IndexCredit v-show="data.tabIndex === TabName.Hotel" :show-why-choose="data.tabIndex !== TabName.Train" />
  <IndexCredit v-show="data.tabIndex === TabName.Flight" :show-introbox="false" />
  <IndexDestination v-show="showIndexDestinationModule" />
  <GuideDownload v-show="data.tabIndex !== TabName.Train && locale !== 'ja-jp'" />
  <!-- 机票和酒店共享 -->
  <IndexComment v-show="data.tabIndex !== TabName.Train" />
  <!-- 机票专属 -->
  <IndexCredit v-show="data.tabIndex === TabName.Flight" :show-why-choose="false" />

  <!-- 火车票专属 -->
  <TrainHowToBuy v-show="data.tabIndex === TabName.Train" />
  <TrainStrategy v-show="data.tabIndex === TabName.Train" />
  <GuideDownload v-show="data.tabIndex === TabName.Train && locale !== 'ja-jp'" />
  <!-- 优惠券弹框 -->
  <CouponDialog page-name="homePage" channel="20002" />
</template>

<style>
.home-top-header{
  height: 702px !important;
}
.top-entry{
  margin-bottom: 100px !important;
}
</style>

<style lang="scss" scoped>

.flex{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.top_search {
  position: absolute;
  z-index: 10;
  top: 78px;
  left: 0;
  right: 0;
}
.top_box {
  position: relative;
  z-index: 10;
  width: 1190px;
  height: 280px;
  margin: 0 auto;
  color: #333 !important;
  background:#FFFFFF;
  // margin-top: 64px;
  fill: #FFF;
  filter: drop-shadow(0 -4px 4px #00c86824);
  border-radius: 16px;
}
.top-line{
  width: 1190px;
  height: 1px;
  margin: 0 auto;
  background: rgba(255,255,255,.3);
}
.top-content{
  position: relative;
  color: #ffffff;
  width: 1190px;
  margin: 0 auto;
  padding-bottom: 24px;
  // margin-bottom: 64px;
}
.download-app {
  position: absolute;
  right: 0;
  text-align: center;
  bottom: -4px;
  // top: calc(-100% + 10px);
  background: url("//file.hopegoocdn.com/hopegoo/web-pc/images/Home_EN_download2.png") no-repeat center center ;
  background-size: 626px 273px;
  z-index: 1;
  width: 626px;
  height: 273px;

  &.jp{
    background: url("//file.hopegoocdn.com/hopegoo/web-pc/images/Home_Japan_Download1.png") no-repeat center center;
    background-size: 466px 251px;
    width: 466px;
    height: 251px;
  }
  &.cn {
    background: url("//file.hopegoocdn.com/hopegoo/web-pc/images/Home_CN_download1.png") no-repeat center center;
    background-size: 626px 273px;
    width: 626px;
    height: 273px;
  }
  &.en {
    background: url("//file.hopegoocdn.com/hopegoo/web-pc/images/Home_EN_download2.png") no-repeat center center;
    background-size: 626px 273px;
    width: 626px;
    height: 273px;
  }
}
.download-app .download-area{
    position: absolute;
}
.download-app .apple-area{
    bottom: 100px;
    right: 220px;
    // background: green;
    width: 100px;
    height: 30px;
}
.download-app .google-area{
    bottom: 55px;
    right: 220px;
    // background: red;
    width: 100px;
    height: 30px;
}
.top-desc {
 font-size: 16px;
 font-weight: 400;
 line-height: 33.6px;
 margin-top: 8px;
 width: calc(100% - 648px);
 height: 67px;
}
.top-desc .jp {
  width: calc(100% - 466px);
}
.top-title {
  font-size: 40px;
  font-weight: 700;
  line-height: 56px;
}
.tab_ul {
  position: absolute;
  top: 30px;
  left: 45px;
  font-size: 0;
  height: 53px;
  display: flex;
  align-items: flex-end;
}

.tab_li {
  font-size: 20px;
  font-weight: 500;
  color: #000;
  cursor: pointer;
  position: relative;
  margin-bottom: 4px;
  transform: translate3d(0,0,0);
  transition: 300ms;
  line-height: normal; // 闪的问题在这里。。。
}

.tab_li.active {
  font-size: 32px;
  margin-bottom: 1px;
}

.tab_li.active:after {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  content: '';
  width: 30px;
  height: 4px;
  background: var(--Brand-Green);
  bottom: -13px;
  border-radius: 2px;
}

.cut_line {
  width: 1px;
  height: 18px;
  background: #333;
  margin: 0 20px 10px;
  transform: translate3d(0,0,0);
  transition: 300ms;
}

.tab_content{
  position: absolute;
  top: 130px;
  left: 0;
  width: 100%;
  z-index: 2;
  &.flight_content{
    top:100px;
  }
}
</style>
