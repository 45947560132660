export default function copyText(button, content, success) {
  if (!button) {
    return
  }

  if (typeof content == 'function') {
    success = content
    content = null
  }

  success = success || function () {}

  // 是否降级使用
  let isFallback = !navigator.clipboard

  if (typeof button == 'string' && !content) {
    if (content === false) {
      isFallback = true
    }
    content = button
    button = null
  }

  const funCopy = function (text, callback) {
    callback = callback || function () {}

    if (!isFallback) {
      navigator.clipboard.writeText(text).then(() => {
        callback()
        // 成功回调
        success(text)
      }, () => {
        // 禁止写入剪切板后使用兜底方法
        copyText(text, false)
        callback()
        // 成功回调
        success(text)
      })

      return
    }

    const textarea = document.createElement('textarea')
    document.body.appendChild(textarea)
    // 隐藏此输入框
    textarea.style.position = 'fixed'
    textarea.style.clip = 'rect(0 0 0 0)'
    textarea.style.top = '10px'
    // 赋值
    textarea.value = text
    // 选中
    textarea.select()
    // 复制
    document.execCommand('copy', true)
    // 移除输入框
    document.body.removeChild(textarea)

    // eleTextarea.value = text
    // eleTextarea.select()
    // document.execCommand('copy', true)

    callback()
    // 成功回调
    success(text)
  }

  funCopy(content)
}
