<script setup lang="ts">
import { computed, defineProps, onMounted, ref } from 'vue'
import { ElMessage } from 'element-plus'
import BScroll from '@better-scroll/core'
import couponCard from './couponCard.vue'
import couponDetail from './couponDetail.vue'
import { couponTrack, getCoupon, request } from './helper'

const props = defineProps({
  pageName: {
    type: String,
    default: '',
  },
  /** 优惠券的渠道，每个渠道不一样 */
  channel: {
    type: String,
    default: '',
  },
})

const emit = defineEmits(['getCallback'])

const listBox = ref<HTMLElement | null>(null)

let scrollLeft = 0

// 是否领取过
const saved = ref(false)

const couponsInfo = ref<Partial<{
  badgeImg: string
  title: string
  configId: string
  bgImg: string
  /** 领取按钮文案 */
  buttonText: string
  /** 显示详情的文案 */
  detailText: string
  /** 拷贝按钮文案 */
  copyText: string
  /** 拷贝成功文案 */
  copySuccessText: string
  itemList: Array<{ [key: string]: any }>
}>>({
  itemList: [],
})

const showDetail = ref(false)
const detailInfo = ref(null)
/**
 *
 * 是否所有卡券都被领取了
 *
 *  */
const isGetAllTicket = computed(() => {
  return couponsInfo.value?.itemList?.every((item: any) => +item.status === 1)
})

let bs: any

function emitDetail(e: any) {
  detailInfo.value = {
    ...e,
    left: e.left - scrollLeft, // - listBoxPos?.left,
    copySuccessText: couponsInfo.value?.copySuccessText,
  }
  showDetail.value = true
}

function fetchCouponList() {
  if (!props.pageName) { return }

  return request({
    url: '/sea_octopus_api/popup/operationList',
    method: 'POST',
    baseURL: '/sea_shell',
    body: {
      pageName: props.pageName,
      channel: props.channel,
    },
  })
    .then((result) => {
      if (result?.status === 200) {
        if (result?.body) {
          couponsInfo.value = result.body
        }
        else {
          throw result
        }
        bs?.refresh()
      }
      else {
        throw result
      }
    }).catch((err) => {
      console.error('获取优惠券失败:', err)
      bs?.refresh()
      couponsInfo.value = {
        itemList: [],
      }
    })
}

function getAllCouponsHandler() {
  if (isGetAllTicket.value) {
    emit('getCallback', { status: 1 })
    return
  }

  const batchList = couponsInfo.value?.itemList?.filter(item => +item.status !== 1).map(item => item.batchNo)

  const trackEvent = (isOk: any) => {
    couponTrack({
      eventid: 12048,
      action: 'click',
      label: 'OperatingPromoCode',
      value: { PromoCode: batchList, PromoCodeState: isOk },
    })
  }

  getCoupon({
    configId: couponsInfo.value?.configId,
    channel: props.channel,
    pageName: props.pageName,
    batchNoList: batchList as string[],
  })
    .then((result: any) => {
      if (result.status === 200) {
        fetchCouponList()
        emit('getCallback', { status: 1 })
        ElMessage.success(result.message)
        trackEvent('1')
      }
      else {
        throw result
      }
    }).catch((err: unknown) => {
      trackEvent('0')
      emit('getCallback', { status: 0 })
      console.error('领取失败', err)
    })
}
/** 领取单个组件的回调 */
function getSingleCouponCallback({ status }: { status: number }) {
  if (+status === 1) {
    fetchCouponList()
  }
  emit('getCallback')
}

function listBoxScrollListener(event: Event) {
  showDetail.value = false
  detailInfo.value = null
  scrollLeft = (event.target as HTMLElement)?.scrollLeft || 0
}

onMounted(() => {
  // console.log(listRef.value)
  // document.querySelector('.main-coupon-list')?.addEventListener('scroll', listBoxScrollListener)
  // 事件委托
  document.addEventListener('click', (event) => {
    const target = event.target as HTMLElement
    if (!target?.matches('.detail-box, .detail-box *, .detail-ctrl') && showDetail.value) {
      // 执行相应操作
      showDetail.value = false
      detailInfo.value = null
    }
  })

  fetchCouponList()?.then((result) => {
    setTimeout(() => {
      bs = new BScroll('.main-coupon-list', {
        scrollX: true,
        scrollY: false,
        bounce: false,
        click: true,
        // bindToWrapper: false,
        disableTouch: true,
        disableMouse: false,
      })
      bs.on('beforeScrollStart', () => {
        showDetail.value = false
        detailInfo.value = null
      })
      bs.on('scrollEnd', () => {
        scrollLeft = -bs.x
      })
    }, 2000)
  }).catch((err) => {
    console.error('获取优惠券失败:', err)
  })
})
</script>

<template>
  <div
    ref="listBox" class="coupons-list" :style="{
      display: couponsInfo.itemList?.length ? 'block' : 'none',
      backgroundImage: `url(${couponsInfo.bgImg})`,
    }"
  >
    <div class="title-box flex">
      <p class="title bold f28 flex-1">
        {{ couponsInfo.title }}
      </p>
      <p
        v-if="!saved" class="font-bold cursor-pointer " :class="{
          'claim-btn': !isGetAllTicket,
          'claim-txt': isGetAllTicket,
        }" @click="getAllCouponsHandler"
      >
        {{ couponsInfo.buttonText }}
      </p>
    </div>
    <div class="list main-coupon-list overflow-hidden w-full">
      <div class="list-box inline-flex">
        <couponCard
          v-for="item in couponsInfo.itemList" :key="item.batchNo" :config-id="couponsInfo.configId"
          :page-name="props.pageName" :detail-btn-txt="couponsInfo.detailText" :info="item" :channel="channel"
          @emit-detail="emitDetail" @get-callback="getSingleCouponCallback"
        />
      </div>
    </div>
    <couponDetail v-if="showDetail" :info="detailInfo || {}" :copy-txt="couponsInfo.copyText" />
  </div>
</template>

<style lang="scss" scoped>
@tailwind base;
@tailwind components;
@tailwind utilities;

.bold {
  font-weight: bold;
}

.flex {
  display: flex;
  align-items: center;
}

.no-warp {
  white-space: nowrap;
}

.flex-1 {
  flex: 1;
}

.f28 {
  font-size: 28px;
}

.f16 {
  font-size: 16px;
}

.f12 {
  font-size: 12px;
}

.gray {
  color: #666;
}

.green {
  color: #00C868;
}

.coupons-list {
  width: 1190px;
  margin: 38px auto 0;
  padding: 24px;
  background-color: #00C868;
  // height: 244px;
  border-radius: 16px;
  box-sizing: border-box;
  position: relative;
  z-index: 2;
  background-position: top right;
  background-repeat: no-repeat;
  background-size: auto 100%;
}

.title-box {
  margin-bottom: 24px;
  line-height: 32px;

  .title {
    color: #FAFF00;
  }
}

.list {
  .list-box {
    // padding:0 16px;
    // height: 140px;
    flex-wrap: nowrap;
    // overflow-x: auto;
    overflow-y: hidden;
  }
}

.main-coupon-list {
  overflow-x: hidden;
}

.claim-btn {
  height: 28px;
  line-height: 28px;
  padding: 0 16px;
  background-color: #FAFF00;
  border-radius: 14px;
  font-weight: bold;
}

.claim-txt {
  padding-left: 28px;
  background: url(../../assets/img/coupons/done.png) left center / 20px 20px no-repeat;
  height: 28px;
  line-height: 28px;
  color: #FAFF00;
}
</style>
