import cookie from 'js-cookie'
import { http } from '@/utils/http'
import { trackEvent } from '@/utils/track/index'

export function getCoupon(coupon: {
  /** 优惠券活动ID */
  configId?: string
  /** 渠道 */
  channel: string
  /** 批量领取 */
  batchNoList: string[]
  pageName: string
}) {
  return request({
    url: '/sea_octopus_api/popup/sendCoupon',
    baseURL: '/sea_shell',
    method: 'POST',
    body: coupon,
  })
}

/**
 * 领取所有的优惠券
 * @param coupons
 * @returns
 */
export function getAllCoupons(coupons: {
  /** 优惠券活动ID */
  configId?: string
  /** 渠道 */
  channel: string
  pageName: string
}) {
  return request({
    url: '/sea_octopus_api/popup/sendCouponAll',
    baseURL: '/sea_shell',
    method: 'POST',
    body: coupons,
  })
}

export async function request(obj: Parameters<typeof http>[0]) {
  if (process.env.EXPORT_COUPON) {
    const token = cookie.get('sectoken')
    if (!token) {
      return Promise.reject(new Error('未登录'))
    }
    const env = window.location.hostname.match(/(\.t\.)|(\.qa\.)/)?.[1] || '.'
    const requestPath = `https://www${env}hopegoo.com/sea_shell${obj.url}`
    const res = await fetch(requestPath, {
      method: obj.method,
      body: JSON.stringify(obj.body),
      headers: Object.assign({
        'Content-Type': 'application/json',
        'HopeGoo-Token': token,
        ...(window.__couponModuleConfig__ || {}),
        'HopeGoo-Timezone': Intl.DateTimeFormat().resolvedOptions().timeZone,
        'HopeGoo-Currency': cookie.get('currency') || '',
      }, obj.headers || {}),
    })
    try {
      return await res.json()
    }
    catch (error) {
      throw res
    }
  }
  else {
    const token = useComCookie('sectoken').value
    if (!token) {
      return Promise.reject(new Error('未登录'))
    }

    return http(obj)
  }
}

/**
 * 埋点辅助函数
 * @param parans
 */
export function couponTrack(parans: Omit<Parameters<typeof trackEvent>[0], 'category'> & Partial<{ category: string }>) {
  const category = window.__couponModuleConfig__?.category || 'homepage'
  const platform = window.__couponModuleConfig__?.['HopeGoo-Platform'] || 'PC'
  parans.category = `${platform}-${category}`
  if (process.env.EXPORT_COUPON) {
    // 导出的时候用外部的埋点
    if (typeof window._extendTrackEvent === 'function') {
      window._extendTrackEvent(parans)
    }
  }
  else {
    trackEvent(parans as Parameters<typeof trackEvent>[0])
  }
}
