<script>
import { ElMessage } from 'element-plus'
import { couponTrack, getCoupon } from './helper'

export default {
  name: 'CouponCard',
  props: {
    info: {
      type: Object,
      default() {
        return null
      },
    },
    channel: {
      type: String,
      default: '',
    },
    configId: {
      type: [String, Number],
      default: '',
    },
    pageName: {
      type: String,
      default: '',
    },
    detailBtnTxt: {
      type: String,
      default: '',
    },
  },
  emits: ['emitDetail', 'getCallback', 'cardWidthRefresh'],
  data() {
    return {
      card: null,
      width: null,
    }
  },
  mounted() {
    this.card = this.$refs.card
    if (this.info?.batchNo) {
      couponTrack({
        eventid: 12049,
        action: 'show',
        label: 'OperatingPromoCode',
        value: { PromoCode: [this.info.batchNo], PromoCodeState: this.info.status ? '已领取' : '未领取' },
      })
    }
    if (this.card) {
      // 监听contentRef高度变化
      this.observer = new ResizeObserver((entries) => {
        for (const entry of entries) {
          this.width = entry.contentRect.width
        }
        this.$emit('cardWidthRefresh', { width: this.width })
      })

      if (this.card) {
        this.observer.observe(this.card)
      }
    }
  },
  unmounted() {
    if (this.observer) {
      this.observer.disconnect()
    }
  },
  methods: {
    showDetail() {
      const left = this.card.offsetLeft
      this.$emit('emitDetail', {
        left,
        item: {
          ...this.info,
        },
      })
    },
    /** 点击领取 */
    getTicket() {
      if (+this.info.status === 1) {
        return
      }
      const trackEvent = (isOk) => {
        couponTrack({
          eventid: 12048,
          action: 'click',
          label: 'OperatingPromoCode',
          value: { PromoCode: [this.info.batchNo], PromoCodeState: isOk },
        })
      }
      getCoupon({
        configId: this.configId,
        channel: this.channel,
        batchNoList: [this.info.batchNo],
        pageName: this.pageName,
      })
        .then((result) => {
          if (result.status === 200) {
            this.$emit('getCallback', { status: 1 })
            ElMessage.success(result.message)
            trackEvent('1')
          }
          else {
            throw result
          }
        }).catch((err) => {
          trackEvent('0')
          this.$emit('getCallback', { status: 0 })
          console.error('领取失败', err)
        })
    },
  },
}
</script>

<template>
  <div
    ref="card" class="card m-flex"
    :data-width="width"
    :style="{
      width: width ? `${width}px` : 'auto',
    }"
  >
    <div class="business m-flex mx-[15px] flex-shrink-0">
      <img class="icon" :src="info.iconPopUrl">
    </div>
    <div class="content no-warp">
      <div class="f22 bold tit">
        {{ info.title }}
      </div>
      <div class="f16 bold info">
        {{ info.promoTitle }}
      </div>
      <div class="f12 gray sub-info">
        {{ info.validText }}
      </div>
      <div class="m-flex">
        <div class="flex-1 green detail-ctrl cursor-pointer " @click="showDetail">
          {{ detailBtnTxt }}
        </div>
        <div
          class="cursor-pointer font-bold" :class="{
            'claim-btn': +info.status !== 1,
            'claim-txt': +info.status === 1,
          }"
          @click="getTicket"
        >
          {{ info.buttonText }}
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@tailwind base;
@tailwind components;
@tailwind utilities;

.bold {
    font-weight: bold;
}

.m-flex {
    display: flex;
    align-items: center;
}

.no-warp {
    white-space: nowrap;
}

.flex-1 {
    flex: 1;
}

.f22 {
    font-size: 22px;
}

.f16 {
    font-size: 16px;
}

.f12 {
    font-size: 12px;
}

.gray {
    color: #666;
}

.green {
    color: #00C868;
}

.card {
    & + .card {
      margin-left: 16px;
    }
    height: 140px;
    position: relative;
    // border-radius: 10px;
    // background: #fff;
    box-sizing: border-box;
    min-width: 342px;
    width: auto;
    // width: 0;
    // flex: 1;
    line-height: 1.4;
    flex-shrink: 0;
    // background-image: url('../../assets/img/coupons/card.png');
    // background-size: 100% auto;
    border-image: url('./ticket-list.png') 24 24 24 216 fill;
    border-image-width: 8px 8px 8px 72px;
    // &::after,
    // &::before {
    //     content: '';
    //     position: absolute;
    //     left: 58px;
    //     height: 12px;
    //     width: 12px;
    //     border-radius: 50%;
    //     background-color: #00C868;
    //     box-sizing: border-box;
    // }

    &::after {
        bottom: -6px;
    }

    &::before {
        top: -6px;
    }

    .business {
        justify-content: center;

        .icon {
            width: 32px;
            height: 32px;
            background: center center / 100% 100% no-repeat;
        }

        .hotel {
            background-image: url('../../assets/img/coupons/hotel-icon.png');
        }

        .flight {
            background-image: url('../../assets/img/coupons/flight-icon.png');
        }

        .train {
            background-image: url('../../assets/img/coupons/train-icon.png');
        }
    }

    .tit {
        height: 30px;
        line-height: 30px;
        margin-bottom: 2px;
    }

    .info {
        height: 22px;
        line-height: 22px;
        margin-bottom: 4px;
    }

    .sub-info {
        height: 16px;
        line-height: 16px;
        margin-bottom: 12px;
    }

    .content {
        padding-left: 16px;
        flex: 1;
        position: relative;
        padding-right: 20px;
        // &::before{
        //     position: absolute;
        //     content:'';
        //     left:0;
        //     height: 176px;
        //     width:1px;
        //     background: #DCD1FC;
        //     top:50%;
        //     transform: translateY(-50%) scale(0.5);
        // }
    }
}

.detail {
    position: absolute;
    top: 120px;
    left: 50px;
    width: 200px;
    height: 400px;
    background-color: #00C868;
}

.claim-btn {
    height: 28px;
    line-height: 28px;
    padding: 0 16px;
    background-color: #00C868;
    color: #fff;
    border-radius: 14px;
}

.claim-txt {
    padding-left: 20px;
    background: url(./checked.png) left center / 16px 16px no-repeat;
    height: 28px;
    line-height: 28px;
    color:#00C868;
}
</style>
