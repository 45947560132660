<script>
import { ElMessage } from 'element-plus'
import copyText from '../../utils/copyText.js'

export default {
  name: 'CouponDetail',
  props: {
    info: {
      type: Object,
      default() {
        return null
      },
    },
    copyTxt: {
      type: String,
      default: '',
    },
  },
  data() {
    return {}
  },
  computed: {
    rulesVHTML() {
      return (this.info.item.couponUseRule || '').replace(/\n/g, '<br/>')
    },
  },
  methods: {
    copyPromoCode() {
      copyText(this.info.item.promotionCode, () => {
        ElMessage.success(this.info.copySuccessText)
      })
    },
  },
}
</script>

<template>
  <div class="coupon-detail text-black" :style="{ left: `${info?.left || 0}px` }">
    <div class="detail-box leading-[1.4]">
      <div class="text-[22px] font-bold mb-[16px]">
        {{ info.item.title }}
      </div>
      <div v-if="info.item.promoDetailTitle" class="text-[14px]">
        <span class="promo-title">{{ info.item.promoDetailTitle }}</span>
        <span class="text-[#00C868] text-[14px] cursor-pointer ml-[8px]" @click="copyPromoCode">{{ copyTxt }}</span>
      </div>
      <div class="text-[14px] mt-[8px]">
        {{ info.item.validDetailText }}
      </div>
      <div class="border-dashed border border-[#E4E4E4] my-[20px] scale-y-50" />
      <div class="text-[14px] font-bold mb-[8px] ">
        {{ info.item.rulesTitle }}
      </div>
      <div v-html="rulesVHTML" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@tailwind base;
@tailwind components;
@tailwind utilities;

.bold {
  font-weight: bold;
}

.flex {
  display: flex;
  align-items: center;
}

.no-warp {
  white-space: nowrap;
}

.flex-1 {
  flex: 1;
}

.f22 {
  font-size: 22px;
}

.f16 {
  font-size: 16px;
}

.f12 {
  font-size: 12px;
}

.gray {
  color: #666;
}

.green {
  color: #00C868;
}

.coupon-detail {
  position: absolute;
  width: 400px;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.2);
  z-index: 10;
  border-radius: 10px;
  box-sizing: border-box;
  top: 212px;

  &::before {
    content: '';
    position: absolute;
    height: 14px;
    width: 14px;
    top: -7px;
    left: 92px;
    transform: rotate(45deg);
    background-color: #fff;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.2);
    z-index: 0;
  }

  .detail-box {
    position: relative;
    z-index: 1;
    padding: 16px;
    width: 100%;
    min-height: 300px;
    background-color: #fff;
    border-radius: 10px;
  }
}
.promo-title {
  position: relative;
  padding-right: 8px;
  &::after {
    content: '';
    position: absolute;
    display: inline-block;
    width: 1px;
    top: 5px;
    bottom: 5px;
    right: 0;
    background-color: #E4E4E4;
  }
}
</style>
